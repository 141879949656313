<script>
	import Layout from "../../layouts/main"
	import PageHeader from "@/components/page-header"
	import appConfig from "@/app.config"
	import DatePicker from "vue2-datepicker"
	import Multiselect from "vue-multiselect"

	export default {
		page: {
			title: "",
			meta: [
				{
					name: "description",
					content: appConfig.description,
				},
			],
		},
		components: {
			Layout,
			PageHeader,
			DatePicker,
			Multiselect,
		},
		data() {
			return {
				isReportTableBusy: false,
				excelDownloading: false,
				tableData: [],
				title: "LeavesgetAllLeaves",
				items: [
					{
						text: "View",
					},
					{
						text: "LeavesgetAllLeaves",
						active: true,
					},
				],
				corporateId: '',
				totalRows: 1,
				currentPage: 1,
				perPage: 10,
				pageOptions: [10, 25, 50, 100, 500],
				filter: null,
				filterOn: [],
				employeeFilterBranch: [],
				selectedBranch: null,
				sortBy: "StartDate",
				sortDesc: true,
				fields: [
					{
						key: "Action",
						tdClass: "align-center align-middle",
						sortable: false,
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "EmployeeName",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "mobile",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "ApplyDate",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "StartDate",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "EndDate",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "Reason",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "Type",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "leaveStatus",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "AdminReply",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
				],
				leave: "",
				leaveFilterOptions: [
					"Pending",
					"Approved",
					"Denied",
				],
				dateType: "",
				daterange: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
				path: "",
				totalPending: 0,
				totalDenied: 0,
				totalApproved: 0,
			};
		},
		computed: {
			/**
			 * Total no. of records
			 */
			rows() {
				return this.tableData.length;
			},
		},
		mounted() {
			// Set the initial number of items
			this.corporateId = localStorage.getItem('corporateId')
			this.totalRows = this.items.length
			this.getBranchList()
			// this.checkUserRights();
		},
		methods: {
			/**
			 * Search the table data with search input
			 */
			getBranchList: function() {
				// console.log("Corporate ID: " + this.corporateId)
				this.axios.post('corporate/get-branch', {
					corporateId: (this.corporateId !== undefined && this.corporateId !== null) ? this.corporateId : this.$storageData.corporateId,
				}).then(result => {
					this.employeeFilterBranch = result.data.data
					this.selectedBranch = this.employeeFilterBranch[0]
					this.getAllLeaves()
				}).catch(error => {
					console.log(error)
				})
			},
			getColor(status) {
				if (status === 'approved') return "badge badge-pill bg-soft-success font-size-13 bg-soft-success"
				if (status === 'pending') return "badge badge-pill bg-soft-success font-size-13 bg-soft-warning"
				return "badge badge-pill bg-soft-success font-size-13 bg-soft-danger";
			},
			onFiltered(filteredItems) {
				// Trigger pagination to update the number of buttons/pages due to filtering
				this.totalRows = filteredItems.length;
				this.currentPage = 1;
			},
			getAllLeaves: function() {
				this.isReportTableBusy = true;
				this.axios.post("getLeaveDetails", {
					'corpBranchId': this.selectedBranch._id,
					'daterange': this.daterange,
					'date': this.date,
					'leaveStatus': this.leave.toLowerCase(),
				}).then((response) => {
					this.isReportTableBusy = false
					this.tableData = response.data.data.leaveData
					this.totalPending = response.data.data.totalPending
					this.totalDenied = response.data.data.totalDenied
					this.totalApproved = response.data.data.totalApproved
				});
			},
			clearFilter() {
				this.daterange = [new Date(), new Date()];
				this.date = [new Date()]
				this.dateType = ""
				this.leave = ""
				this.selectedBranch = this.employeeFilterBranch[0]
				this.getAllLeaves()
			},
			applyFilter() {
				if (this.daterange.includes(null)) {
					this.daterange = []
				}
				this.getAllLeaves()
			},
			// filterDataByRange(range) {
			// 	var now = new Date();
			// 	switch (range) {
			// 		case 1:
			// 			this.firstActive = "active";
			// 			this.secondActive = "";
			// 			this.thirdActive = "";
			// 			this.date = now;
			// 			this.getAllLeaves();
			// 			break;
			// 		// case 2:
			// 		//     this.firstActive = "";
			// 		//     this.secondActive = "active";
			// 		//     this.thirdActive = "";
			// 		//     var lastWeek = new Date(new Date(now).setDate(now.getDate() - 7));
			// 		//     this.daterange = [lastWeek, now];
			// 		//     this.getAllAttendance();
			// 		//     break;
			// 		// case 3:
			// 		//     this.firstActive = "";
			// 		//     this.secondActive = "";
			// 		//     this.thirdActive = "active";
			// 		//     var lastMonth = new Date(new Date(now).setDate(now.getDate() - 30));
			// 		//     this.daterange = [lastMonth, now];
			// 		//     this.getAllLeaves();
			// 		//     break;
			// 	}
			// },
			downloadSample() {
				this.excelDownloading = true;
				this.axios.post("exportExcel", {
					exportData: this.tableData,
					page: "LeaveReport",
				}, {
					responseType: "blob",
				}).then((response) => {
					this.excelDownloading = false;
					const url = URL.createObjectURL(
						new Blob([response.data], {
							type: "application/vnd.ms-excel",
						}));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", "leave-report.xlsx");
					document.body.appendChild(link);
					link.click();
				});
			},
		},
		middleware: "authentication",
	};
</script>
<style scoped>
	.b-table > tbody > tr:nth-of-type(odd) {
		--bs-table-accent-bg: red !important;
		color: red !important;
	}
	.table > :not(caption) > * > * {
		padding: 5px 8px !important;
	}
	.btn, .btn:hover, .btn:active {
		outline: none;
		box-shadow: none;
	}
</style>
<template>
	<Layout>
		<PageHeader :items="items" />
		<!--FILTER START------>
		<div class="card" style="margin-top: -30px">
			<div class="card-body">
				<div class="inner mb-2 row">
					<!-- Date range -->
					<div class="col-md-2" style="width: 20%">
						<label>Date Range </label>
						<date-picker v-model="daterange" format="DD MMM Y" append-to-body range lang="en">
						</date-picker>
					</div>
					<!-- Date range END-->
					<!-- Payment Type -->
					<div class="col-md-2" style="width: 18%;">
						<label>Select </label>
						<multiselect v-model="leave" :options="leaveFilterOptions" :show-labels="false" />
					</div>
					<!-- Payment Type END-->
					<!-- Branch -->
					<div class="col-md-4" v-if="$storageData.isSuper">
						<label for="branch">Select Branch</label>
						<multiselect id="branch" v-model="selectedBranch" :options="employeeFilterBranch" placeholder="Select Branch" :multiple="false" track-by="name" label="name" :allow-empty="false">
							<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						</multiselect>
					</div>
					<!-- Branch END -->
					<!-- Apply filter -->
					<div class="col-md-2" style="width: auto;">
						<button class="btn btn-outline-primary mt-4" v-on:click="applyFilter()">Apply Filter</button>
					</div>
					<!-- Apply filter END-->
					<!-- Clear filter -->
					<div class="col-md-2" style="width: auto;">
						<button class="btn btn-outline-danger mt-4" v-on:click="clearFilter()">Clear Filter</button>
					</div>
					<!-- Clear filter END-->
				</div>
			</div>
		</div>
		<!--FILTER END------>
		<!--CARDS START  -->
		<div class="row" style="margin: 10px 0 0">
			<div class="col-md-6 col-xl-3 overviewCard">
				<div class="card">
					<div class="card-body">
						<div class="float-end mt-2">
						</div>
						<div>
							<h4 class="cardTitle mb-1 mt-1">
								{{ this.totalPending }}
							</h4>
							<p class="text-danger card-title mb-0">Total Pending</p>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6 col-xl-3 overviewCard">
				<div class="card">
					<div class="card-body">
						<div class="float-end mt-2">
						</div>
						<div>
							<h4 class="cardTitle mb-1 mt-1">
								{{ this.totalApproved }}
							</h4>
							<p class="text-danger card-title mb-0">Total Approved</p>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6 col-xl-3 overviewCard">
				<div class="card">
					<div class="card-body">
						<div class="float-end mt-2">
						</div>
						<div>
							<h4 class="cardTitle mb-1 mt-1">
								{{ this.totalDenied }}
							</h4>
							<p class="text-danger card-title mb-0">Total Denied</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--CARDS END-->
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<div class="row mt-4">
							<div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
								<!-- <div role="group" class="btn-group">
									<button type="button" class="btn btn-outline-primary" @click="downloadSample()">
										<span>Download Report</span>
										<b-spinner v-if="excelDownloading" small type="grow" class="ms-2"></b-spinner>
										<b v-if="excelDownloading" class="ms-2">Loading...</b>
									</button>
								</div> -->
							</div>
							<div class="col-sm-12 col-md-4">
								<div id="tickets-table_length" class="dataTables_length">
									<label class="d-inline-flex align-items-center">
										<span>Show</span>
										<b-form-select style="margin-left:5px; margin-right:5px" v-model="perPage" size="sm" :options="pageOptions"></b-form-select>
										<span>entries</span>
									</label>
								</div>
							</div>
							<!-- Search -->
							<div class="col-sm-12 col-md-4 row">
								<div id="tickets-table_filter" class="dataTables_filter text-md-end">
									<label for="filter" class="d-inline-flex align-items-center">
										<span>Search:</span>
										<b-form-input id="filter" v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm ms-2"></b-form-input>
									</label>
								</div>
							</div>
							<!-- End search -->
						</div>
						<!-- Table -->
						<div class="table-responsive mb-0">
							<b-table striped hover outlined bordered :items="tableData" :fields="fields"
								thead-class="bg-transparent" responsive="sm" :per-page="0"
								:current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
								:filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"
								fixed-header :busy="isReportTableBusy" show-empty>
""								<template #table-busy>
									<div class="text-center text-danger my-2">
										<b-spinner variant="primary" class="align-middle" type="grow"></b-spinner>
										<strong class="text-primary">Loading...</strong>
									</div>
								</template>
								<!--<template v-slot:cell(OutTime)="{ value }">
									<p v-if="value == null">N/A</p>
									<p v-else>{{ value }}</p>
								</template>-->
								<template v-slot:cell(leaveStatus)="{ item }">
									<span :class="`${getColor(item.leaveStatus)}`">{{ item.leaveStatus }}</span>
								</template>
								<template v-slot:cell(Action)="data">
									<router-link
										:to="{ name: 'leaveForm', params: { id: data.item.leaveId, type: 'Edit' } }">
										<i class="uil uil-edit-alt" title="Edit" style="font-size: 19px;"></i>
									</router-link>
								</template>
							</b-table>
						</div>
						<div class="row">
							<div class="col">
								<div class="dataTables_paginate paging_simple_numbers float-end">
									<ul class="pagination pagination-rounded mb-0">
										<b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>